<template>
  <div>
    <h3 class="center">ЗП бариски по часам</h3>
    <div class="heap heapR">
      <div class="heap-block">
        <DateSelector label="Дата нач." :val="date_begin" @change="date_begin = $event" />
      </div>
      <div class="heap-block">
        <DateSelector label="Дата кон." :val="date_end" @change="date_end = $event" />
      </div>
      <div class="heap-block">
        <PeopleSelector
          label="Сотрудник"
          filter="work"
          :id_peopleIn="id_people"
          @change="id_people = $event"
        />
      </div>
      <div class="heap-block">
        <div @click.prevent="tableDataRetrieve" class="high pointer">
          <button class="btn" ref="btn">Обновить</button>
        </div>
      </div>
    </div>

    <div class="heap heapR">
      <div class="flexR reminder">
        Продажи&nbsp;
        <input v-model="salesLimit" type="number" style="text-align: center; width: 3rem" />
      </div>
      <div class="flexR reminder">
        Коэффициент&nbsp;
        <input v-model.number="salesK" type="number" style="text-align: center; width: 3rem" />
      </div>

      <div class="flexR reminder">
        <input v-model.number="showNonBonus" type="checkbox" />
        &nbsp;Показать без премии
      </div>

      <div class="flexR right reminder">
        <input type="checkbox" v-model="columnSetup" />
        &nbsp;Настр.столбц.
      </div>
    </div>

    <div v-show="columnSetup" style="padding-bottom: 1rem">
      <div v-for="(x, i) of columnList" :key="i" class="flexR margins1">
        <input type="checkbox" v-model="columnSelected" :value="x" />
        &nbsp;
        <div class="vertical-list02" v-html="columnListCaption[i]"></div>
      </div>
    </div>

    <div v-if="loading">
      <Loader />
    </div>

    <div v-else>
      <table class="t_table">
        <tr>
          <th v-show="visibleCheck('date')">{{ columns.date }}</th>
          <th v-show="visibleCheck('point')">{{ columns.point }}</th>
          <th v-show="visibleCheck('turn')">{{ columns.turn }}</th>
          <th v-show="visibleCheck('rate')">{{ columns.rate }}</th>
          <th v-show="visibleCheck('hourly')">{{ columns.hourly }}</th>
          <th v-show="visibleCheck('hour')">{{ columns.hour }}</th>
          <th v-show="visibleCheck('salaryNormal')">
            <div v-html="columns.salaryNormal"></div>
          </th>
          <th v-show="visibleCheck('salaryBonus')"><div v-html="columns.salaryBonus"></div></th>
          <th v-show="visibleCheck('bonus')">{{ columns.bonus }}</th>
        </tr>

        <template v-if="this.totalHour > 0">
          <tr v-for="(row, y) in report" :key="y">
            <td v-show="visibleCheck('date')" class="center">
              {{ dateFormatHuman(row.turn_date, "w") }}
            </td>
            <td v-show="visibleCheck('point')">
              {{ row.point }}
              <br />
              <div class="grey">{{ row.coworkers }}</div>
            </td>
            <td v-show="visibleCheck('turn')">
              {{ offsetApply(row.time_begin) }} &mdash;
              {{ offsetApply(row.time_end) }}
              {{ !row.turn_order ? "🌝" : "\u00A0\u00A0\u00A0\u00A0" }}
            </td>
            <td v-show="visibleCheck('rate')">
              {{ row.turn_rate }}
            </td>
            <td v-show="visibleCheck('hourly')">
              <table class="sales">
                <tr
                  v-for="(el, y) in reportExtended[row.turn_date].filter(
                    e => e != undefined && e.id_point == row.id_point
                  )"
                  :key="y"
                  class="sales"
                >
                  <span
                    v-show="
                      el.sales / (el.coworkers + 1) > parseInt(salesLimit) || showNonBonus
                    "
                  >
                    <td class="sales">
                      {{ offsetApply(el.hour) }}-{{ offsetApply(el.hour, true) }}
                    </td>

                    <td class="sales">
                      <font color="blue">[{{ el.sales }}/{{ el.coworkers + 1 }}]</font>
                    </td>

                    <td class="sales">
                      <font
                        :color="
                          (el.sales / (el.coworkers + 1)).toFixed(1) > parseInt(salesLimit)
                            ? 'red'
                            : 'black'
                        "
                      >
                        {{ (el.sales / (el.coworkers + 1)).toFixed(1) }}
                      </font>
                    </td>

                    <td class="sales">
                      <font color="grey">
                        {{
                          (el.sales / (el.coworkers + 1)).toFixed(1) > parseInt(salesLimit)
                            ? row.turn_rate * salesK
                            : row.turn_rate
                        }}
                      </font>
                    </td>
                  </span>
                </tr>
              </table>
            </td>
            <td v-show="visibleCheck('hour')">
              {{ row.hours }} {{ row.hours_add ? `(+${row.hours_add})` : null }}
            </td>
            <td v-show="visibleCheck('salaryNormal')">
              {{ salaryDaily[row.turn_date] }}
            </td>
            <td v-show="visibleCheck('salaryBonus')">{{ salaryDailyBonus[row.turn_date] }}</td>
            <td v-show="visibleCheck('bonus')">
              {{ !bonus[row.turn_date] ? "\u{1F615}" : bonus[row.turn_date] }}
            </td>
          </tr>

          <tr>
            <td v-show="visibleCheck('date')"></td>
            <td v-show="visibleCheck('point')"></td>
            <td v-show="visibleCheck('turn')"></td>
            <td v-show="visibleCheck('rate')"></td>
            <td v-show="visibleCheck('hourly')"></td>
            <td v-show="visibleCheck('hour')">
              <b>{{ this.totalHour }}</b>
            </td>
            <td v-show="visibleCheck('salaryNormal')" class="bold">
              {{ salaryTotal }}
            </td>
            <td v-show="visibleCheck('salaryBonus')" class="bold">{{ salaryTotalK }}</td>
            <td v-show="visibleCheck('bonus')" class="bold">{{ bonusTotal }}</td>
          </tr>
        </template>

        <template v-else>
          <tr>
            <td :colspan="columnSelected.length">нет данных по выбранным параметрам</td>
          </tr>
        </template>
      </table>
    </div>
  </div>
</template>

<style lang="scss" scoped>
th {
  padding: 0 0.4rem;
}

table.sales {
  border: none;
  border-spacing: 0;
}

tr.sales {
  padding: 10px;
}

td.sales {
  border: none;
  padding-right: 0.2rem;
  text-align: right;
}
</style>

<script>
import DateSelector from "@/components/DateSelector.vue";
import PeopleSelector from "@/components/PeopleSelector.vue";
import Loader from "@/components/Loader.vue";
import { dateFormatJS, dateFormatHuman } from "@/components-js/dateFormat";
import { DATE_MAX_INTERVAL } from "@/config/settings";
import { request } from "@/components-js/requestSrv";
import { datesCheck } from "@/components-js/datesCheck.js";
import { lS } from "@/components-js/localStorage";
const loStorage = new lS();
import { offsetApply, hoursArr } from "@/components-js/timeOffset";

export default {
  components: { DateSelector, PeopleSelector, Loader },
  data() {
    return {
      loading: false,
      date_begin: null,
      date_end: null,
      id_people: null,
      report: [],
      reportExtended: [],
      coworkers: [],
      totalHour: 0,
      salaryDaily: [],
      salaryDailyBonus: [],
      bonus: [],
      salaryTotal: 0,
      salaryTotalK: 0,
      bonusTotal: 0,
      sales: [],
      // show/hide people selector for admin/user
      peopleFlag: false,
      // all columns to be shown
      columnList: [],
      // all columns captions
      columnListCaption: [],
      // columns selected to show
      columnSelected: [],
      // selection list show/hide
      columnSetup: false,
      columns: {
        date: "Дата",
        point: "Точка",
        turn: "Cмена",
        rate: "Ставка",
        hourly: "Почасовка",
        hour: "Часов",
        salaryNormal: "ЗП<br/>норм",
        salaryBonus: "ЗП<br/>прем",
        bonus: "Премия",
      },
      salesLimit: 9,
      salesK: 1.5,
      showNonBonus: false,
    };
  },

  watch: {
    columnSelected: function() {
      loStorage.saveObject(this.$route.path, {
        id_people: this.id_people,
        date_begin: this.date_begin,
        date_end: this.date_end,
        columnSelected: this.columnSelected,
      });
    },

    salesLimit: function() {
      this.salaryCalc();
    },
    salesK: function() {
      this.salaryCalc();
    },
  },

  mounted() {
    // set default values for dates and point
    const d = new Date();

    this.date_begin = this.date_end = dateFormatJS(d);

    let tmp = loStorage.getObjectProp(this.$route.path, "date_begin");
    if (tmp) this.date_begin = tmp;

    tmp = loStorage.getObjectProp(this.$route.path, "date_end");
    if (tmp) this.date_end = tmp;

    tmp = loStorage.getObjectProp(this.$route.path, "salesLimit");
    if (tmp) this.salesLimit = tmp;

    tmp = loStorage.getObjectProp(this.$route.path, "salesK");
    if (tmp) this.salesK = tmp;

    tmp = loStorage.getObjectProp(this.$route.path, "showNonBonus");
    if (tmp) this.showNonBonus = tmp;

    // admin and user difference
    if (
      this.$root?.user?.roles.includes("admin") ||
      this.$root?.user?.roles.includes("pointadmin")
    ) {
      // show people selector for admin
      this.peopleFlag = true;
      // get id_people from localStorage
      tmp = loStorage.getObjectProp(this.$route.path, "id_people");
      if (tmp) this.id_people = tmp;
    } else {
      // user always see own table
      this.id_people = this.$root?.user?.uid;
    }

    tmp = loStorage.getObjectProp(this.$route.path, "columnSelected");
    if (this.$root?.user?.roles.includes("admin") && tmp) {
      for (let el of tmp) this.columnSelected.push(el);
    }
    // retreive data anyway
    this.tableDataRetrieve();
  },

  methods: {
    offsetApply(h, l) {
      return offsetApply(h, l);
    },

    visibleCheck(col) {
      return this.columnSelected.includes(col);
    },

    dateFormatHuman(d, w) {
      return dateFormatHuman(d, w);
    },

    // hourly(time_begin, time_end, rate) {
    //   return time_begin + "-" + time_end + "(" + rate + ")";
    // },

    salesGet(id_point, date, hour) {
      if (!this.sales[id_point]) return -1;
      if (!this.sales[id_point][date]) return -1;
      if (!this.sales[id_point][date][hour]) return -1;
      return this.sales[id_point][date][hour];
    },

    coworkersCount(id_point, date, udatetime) {
      return this.coworkers.filter(
        el =>
          el.id_point == id_point &&
          el.turn_date == date &&
          // current hour in turn
          el.utime_begin <= udatetime &&
          el.utime_end >= udatetime
      ).length;
    },

    salaryCalc() {
      // calculate salaryDaily & salaryDailyBonus
      this.salaryDaily = [];
      this.salaryDailyBonus = [];
      for (const d in this.reportExtended) {
        this.salaryDaily[d] = this.salaryDailyBonus[d] = 0;
        for (const el of this.reportExtended[d].filter(el => el != undefined)) {
          this.salaryDaily[d] += el.rateBase;
          const k = el.sales / (el.coworkers + 1) > this.salesLimit ? this.salesK : 1;
          this.salaryDailyBonus[d] += el.rateBase * k;
        }
        this.bonus[d] = this.salaryDailyBonus[d] - this.salaryDaily[d];
      }

      this.salaryTotal = 0;
      this.salaryTotalK = 0;
      for (const el in this.salaryDaily) this.salaryTotal += this.salaryDaily[el];
      for (const el in this.salaryDailyBonus) this.salaryTotalK += this.salaryDailyBonus[el];
      this.bonusTotal = this.salaryTotalK - this.salaryTotal;
    },

    async tableDataRetrieve() {
      this.loading = true;

      const res = datesCheck(this.date_begin, this.date_end, DATE_MAX_INTERVAL);
      this.date_begin = dateFormatJS(res.date_begin);
      this.date_end = dateFormatJS(res.date_end);

      const data = await request(
        "/api/report/personal_turns",
        "POST",
        {
          id_people: this.id_people,
          date_begin: this.date_begin,
          date_end: this.date_end,
          columnSelected: this.columnSelected,
          salesLimit: this.salesLimit,
          salesK: this.salesK,
          showNonBonus: this.showNonBonus,
        },
        this.$route.path
      );

      this.totalHour = 0;
      for (let i in data) this.totalHour += data[i].hours;

      if (!this.totalHour) this.totalHour = -1;
      else {
        // if turns found, get coworkers
        this.coworkers = await request("/api/report/personal_turns_coworkers", "POST", {
          id_people: this.id_people,
          date_begin: this.date_begin,
          date_end: this.date_end,
        });

        // through each turn from data find coworkers
        let list = [];
        // !!! change in --> of
        // !!! data[i] --> i
        for (let trn of data) {
          list = this.coworkers.filter(
            el =>
              trn.turn_date == el.turn_date &&
              trn.id_point == el.id_point &&
              ((trn.utime_begin >= el.utime_begin && trn.utime_begin <= el.utime_end) ||
                (trn.utime_end > el.utime_begin && trn.utime_end <= el.utime_end) ||
                (el.utime_begin > trn.utime_begin && el.utime_end < trn.utime_end) ||
                (trn.utime_begin == el.utime_begin && trn.utime_end == el.utime_end))
          );
          if (list.length) {
            // array of coworkers per turn
            trn.coworkers = "";
            for (let l in list.sort((a, b) => a.utime_end - b.utime_end)) // order y time_end desc
              trn.coworkers +=
                (list[l].coworker_nickname
                  ? list[l].coworker_nickname
                  : list[l].coworker_name) + ", ";
          }
        }
      }
      this.report = data;

      const salesTemp = await request("/api/sales/salesgetsalarly", "POST", {
        id_people: this.id_people,
        date_begin: this.date_begin,
        date_end: this.date_end,
      });

      // copy one-dimension array into three-dimension array
      this.sales = [];
      for (const el of salesTemp) {
        if (this.sales[el.id_point] == undefined) this.sales[el.id_point] = [];
        if (this.sales[el.id_point][el.date] == undefined)
          this.sales[el.id_point][el.date] = [];
        this.sales[el.id_point][el.date][el.hour] = el.sales;
        // for to count bariskas
        this.sales[el.id_point][el.date][el.udatetime] = true;
      }

      this.reportExtended = [];
      // full-info array at least
      for (const el of this.report) {
        // el: turn with attributes

        for (let hour of hoursArr(el.time_begin, el.time_end, el.hours_add, true)) {
          const date = el.turn_date;
          const sales = this.salesGet(el.id_point, el.turn_date, hour);
          const coworkers = this.coworkersCount(
            el.id_point,
            el.turn_date,
            el.utime_begin + (hour + 1 - el.time_begin) * 60 * 60
          );
          // const k = sales / coworkers > this.salesLimit ? this.salesK : 1;
          // const rate = el.turn_rate * k;

          // console.log(`hour ${hour} sales ${sales} coworkers ${coworkers} rate ${rate}`);
          if (this.reportExtended[date] == undefined) this.reportExtended[date] = [];
          this.reportExtended[date][hour] = {};
          this.reportExtended[date][hour].hour = hour;
          this.reportExtended[date][hour].sales = sales;
          this.reportExtended[date][hour].coworkers = coworkers;
          this.reportExtended[date][hour].rateBase = el.turn_rate;
          this.reportExtended[date][hour].id_point = el.id_point;
        }
      }

      this.salaryCalc();

      // default column selection
      if (!this.columnSelected.length) {
        this.columnSelected.push("date");
        this.columnSelected.push("point");
        this.columnSelected.push("turn");
        this.columnSelected.push("hour");
        this.columnSelected.push("hourly");
      }

      this.columnList = [];
      this.columnListCaption = [];

      this.columnList.push("date");
      this.columnListCaption.push(this.columns.date);
      this.columnList.push("point");
      this.columnListCaption.push(this.columns.point);
      this.columnList.push("turn");
      this.columnListCaption.push(this.columns.turn);
      this.columnList.push("rate");
      this.columnListCaption.push(this.columns.rate);
      this.columnList.push("hourly");
      this.columnListCaption.push(this.columns.hourly);
      this.columnList.push("hour");
      this.columnListCaption.push(this.columns.hour);
      this.columnList.push("salaryNormal");
      this.columnListCaption.push(this.columns.salaryNormal);
      this.columnList.push("salaryBonus");
      this.columnListCaption.push(this.columns.salaryBonus);
      this.columnList.push("bonus");
      this.columnListCaption.push(this.columns.bonus);

      this.loading = false;
    },
  },
};
</script>
